<template>
	<div class="dashboard_buttons">
		<DsText html-element="h3" text-type="heading-4xl" class="dashboard_buttons__headline">{{vm.BaseViewModel.Headline}}</DsText>
		<DsText html-element="div" text-style="regular" text-type="body-md" class="dashboard_buttons__description" >
			<span v-html="vm.BaseViewModel.DescriptionText"></span>
		</DsText>
		<DsButtonGroup desktopAlignment="center" mobile-alignment="stretch" mobile-direction="column">
			<DsButton :href="vm.BaseViewModel?.NewEnrollmentPageLink" variant="primary">{{vm.BaseViewModel.NewEnrollmentButtonText}}</DsButton>
			<DsButton variant="secondary" :href="vm.BaseViewModel.DashboardPageLink">{{vm.BaseViewModel.DashboardButtonText}}</DsButton>
		</DsButtonGroup>
	</div>

</template>

<script>
import { DsText, DsButton, DsButtonGroup } from '@coloplast/design-system';

export default {
	name: 'enrollment-form-dashboard-buttons',
	components: { DsText, DsButton, DsButtonGroup },
	props: {
		vm: {
			type: Object,
			required: true,
		},
	},
}
</script>

<style lang="scss" scoped>
	@import 'multisite/styles/settings/settings';
	@import 'multisite/styles/tools/tools';

	.dashboard_buttons {
		text-align: center;
		margin: ds-space(12) auto 0;

		@include t-respond-to-min('medium') {
			width: 625px;
		}

		&__headline {
			margin-bottom: 20px;
		}

		&__description {
			margin-bottom: 25px;
		}
	}
</style>
