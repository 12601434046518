import { createApp } from 'vue';
import store from 'shared/store';
import EnrollmentFormDashboardButtons from './enrollmentFormDashboardButtons.vue';

const enrollmentFormDashboardButtons = document.getElementById('enrollment-form-dashboard-buttons');

if (enrollmentFormDashboardButtons) {
	const app = createApp({
		name: 'EnrollmentFormDashboardButtonsApp',
	});
	app.use(store);
	app.component('enrollment-form-dashboard-buttons', EnrollmentFormDashboardButtons);
	app.mount(enrollmentFormDashboardButtons);
}